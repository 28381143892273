.buying-card {
  display: inline-block;
  position: relative;
}

.voucher-buy-wrapper__img {
  max-width: 250px;
}

.buying-card__content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.buying-card__content_verso {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}

.buying-card__flex {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1em;
}

.buying-card__title {
  font-family: "Gilroy Bold", sans-serif;
  font-size: 3rem;
  color: #fff;
}

.buying-card__subtitle {
  display: inline;
  color: #fff;
  font-size: 0.9rem;
}
